.date-picker {
  --dp-font-family: $font-stack,
  --dp-border-radius: 0;
  --dp-cell-border-radius: 0;
  --dp-month-year-row-height: 30px;
  --dp-month-year-row-button-size: 15px;
  --dp-button-icon-height: 15px;
  --dp-cell-size: 35px;
  --dp-cell-padding: 10px 0;
  --dp-row-margin: 0;
  --dp-font-size: 0.8rem;

  input {
    @extend input;
    height: initial;
    line-height: initial;
    box-shadow: none;

    &::placeholder {
      color: $grey-dark;
    }

    &:hover {
      @extend input:hover;
    }

    &:focus {
      @extend input:focus;
    }
  }

  &.dp__invalid-field .dp__input {
    border: 1px solid $red !important;
    box-shadow: 0 0 0 1px $red;
  }

  &.dp__warning-field .dp__input {
    border: 1px solid $yellow !important;
    box-shadow: 0 0 0 1px $yellow;
  }

  .dp__today {
    border: none;
    color: $primary;

    &.dp__active_date {
      color: $white;
    }
  }

  .dp--year-select,
  .dp__month_year_select,
  .dp__overlay_action {
    color: $black;

    &:hover {
      border: 0;
      background-color: #fff1d9;
      color: $primary;

      .dp__icon {
        color: $primary;
        background-color: #fff1d9;
      }
    }
  }

  .dp--arrow-btn-nav,
  .dp__icon {
    color: $black;

    &:hover {
      color: $primary;
      border: 0;
      background-color: $white;
    }
  }

  .dp__theme_light {
    --dp-text-color: #969696;
    --dp-hover-color: #dcddde;
    --dp-hover-text-color: #000;
    --dp-hover-icon-color: var(--primary);
    --dp-primary-color: var(--primary);
    --dp-primary-text-color: #fff;
    --dp-secondary-color: #ccc;
    --dp-icon-color: #000;
    --dp-range-between-dates-background-color: #fff1d9;
    --dp-range-between-border-color: none;
  }

  .dp__cell_disabled {
    background-color: $grey-lighter;
    color: #ccc;
  }
  
  .dp__cell_disabled:hover {
    border: none;
  }
  
  .dp__input_icon {
    right: 0;
    left: auto;
    top: 50%;
    --dp-font-size: 1rem;
  }

  .dp__input {
    height: 40px;

    &:hover {
      border: 1px solid $grey;
    }
  }

  .mx-shortcuts-wrapper {
    padding-top: 10px;
    text-align: center;

    .mx-shortcuts {
      margin: 0 10px;
      color: $grey;
      font-size: 0.75rem;

      &:hover {
        box-shadow: none;
        color: var(--primary);
      }

      &::after {
        content: '';
        margin: 0;
      }
    }
  }
}

@media (max-width: $mq-lg) {
  .date-picker {
    .dp__cell_inner {
      width: 100%;
      min-width: 40px;
    }
  }
}
