.progress-bar-container {
  .progress {
    display: inline-block;
    width: 80%;
    margin: 0.5rem 0.25rem 0 0;

    .progress-bar {
      max-width: 100%;

      &.no-animation {
        background-image: none;
        animation: none;
      }

      &.red {
        background-color: $red;
      }

      &.orange {
        background-color: var(--primary);
      }

      &.green {
        background-color: $green;
      }
    }
  }

  .percentage {
    display: inline-block;
    margin: 0;
    font-weight: bold;
  }
}
