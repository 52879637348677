.slider-indicator-icon {
  margin: 0 0.5rem !important;
  width: 15px !important;
  height: 15px !important;
  background-color: $white !important;
}

.slider-indicator-active {
  background-color: var(--primary) !important;
}
