.stats {
  .stats-container {
    position: relative;
  }

  .header-row {
    .title {
      display: flex;
      align-items: center;
    }
  }

  .breadcrumbs {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: $grey;

    a {
      color: $grey;
    }
  }

  .panel {
    margin-bottom: 1rem;
  }

  .indicator {
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $grey-lighter;
    }

    &.impressions.active {
      &::after {
        background: $green;
      }
    }

    &.ctr.active {
      &::after {
        background: $red;
      }
    }

    &.actions.active {
      &::after {
        background: var(--primary);
      }
    }

    &.cpa.active {
      &::after {
        background: $yellow;
      }
    }

    &.clicks.active {
      &::after {
        background: $blue;
      }
    }

    &.cpc.active {
      &::after {
        background: $purple;
      }
    }

    &.total.active {
      &::after {
        background: $grey-light;
      }
    }

    &.cpm.active {
      &::after {
        background: $grey-dark;
      }
    }
  }

  .detailed-stats-container {
    .detailed-stats-wrapper {
      .panel {
        .grid-wrapper {
          margin: -1rem;
        }
      }
    }
  }

  .recommendations {
    .panel {
      height: calc(100% - 1.5rem);
    }

    span.pacing {
      font-weight: bold;
    }

    .recommendation {
      display: inline-block;
    }

    .progress-bar-container {
      margin: 0.5rem 0;

      .progress {
        width: 100%;
      }
    }
  }

  .heatmap {
    height: 550px;
    margin: -1rem;

    .heatmap-wrapper {
      @include border-radius;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;

      .vue-map {
        height: 550px;
      }
    }
  }

  .disclaimer {
    margin-top: 2rem;
  }
}
