#app {
  padding: 2rem 3rem;

  .title-bar {
    padding: 0.1rem 3rem;

    .title {
      position: relative;

      h1 {
        display: inline-block;
        max-width: calc(100% - 100px);
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .badge {
      display: inline-block;
      margin-left: 10px;
    }
  }

  footer.footer-bar {
    position: fixed;
    width: calc(100% - 240px);
    min-height: 65px;
    bottom: 0;
    left: 240px;
    padding: 0.5rem 3rem;
    background: $white;
    box-shadow: 0 1px 3px 1px rgba($black, 0.15);
    z-index: 10;

    .title {
      margin-bottom: 0;
      color: $grey;
      font-size: 0.9rem;

      .badge {
        display: inline-block;
        margin-left: 10px;
        vertical-align: 140%;
      }
    }

    .text {
      display: inline;
      margin-top: 0;
      font-weight: bold;

      &.danger {
        color: $red;
      }
    }

    .i-alert {
      font-size: 1.5rem;

      &.error {
        color: $red;
      }

      &.warning {
        color: $yellow;
      }
    }

    .footer-price {
      display: inline-block; 
      margin-right: 4px;
    }

    .right-actions {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 0.5rem;

      .btn-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .mobile {
        display: none;
      }
    }

    @media (max-width: $mq-lg) {
      position: relative;
      width: calc(100% + 2rem);
      padding: 1rem;
      bottom: -2rem;
      left: -1rem;

      .breadcrumb {
        display: none;
      }

      .right-actions {
        justify-content: space-between;

        .mobile {
          display: block;
        }
      }

      .left {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }

  .header-row {
    height: 100%;
  }

  @media (max-width: $mq-lg) {
    padding: 2rem 1rem;

    .title-bar {
      width: calc(100% + 2rem);
      margin: -1rem 0 0 0;
      padding: 1rem;
    }
  }
}
