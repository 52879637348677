html,
body {
  margin: 0;
  padding: 0;
  font-family: $font-stack;
  font-weight: 300;
  font-size: 15px;
  color: $black;
}

* {
  outline: 0;
}

.container {
  position: relative;
  max-width: $mq-xxl;
}
