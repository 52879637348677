.briefs {

  .jexcel {
    width: 100%;

    td {
      z-index: 0;
    }

    .jexcel_selectall {
      width: 10px;
    }

    .highlight {
      background-color: rgba(var(--primary-rgb), 0.1);
    }

    .highlight-right {
      border-right-color: var(--primary);
    }

    .highlight-left {
      border-left-color: var(--primary);
    }

    .highlight-bottom {
      border-bottom-color: var(--primary);
    }

    .highlight-top {
      border-color: var(--primary);
    }
  }

  .container {

    .desc {

      .ui.fluid.search.selection {
        margin: 0.75rem 0;
      }

      .brief-desc {
        height: calc(100% - 1.5rem);
      }
    }
  }

  .btn:disabled,
  .btn[disabled] {
    background-color: $grey-light;
    cursor: not-allowed;
  }
}
