@keyframes expand {
  from {
    opacity: 0;
    transform: scaleY(0);
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

.slide-down-enter {
  transform: translateY(-25px);
  opacity: 0;
  height: 0;
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.5s ease;
}

.slide-down-leave {
  height: 100px;
}

.slide-down-leave-to {
  transform: translateY(-5px);
  opacity: 0;
  height: 0;
}

.popup-transition-enter {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.popup-transition-enter-active,
.popup-transition-leave-active  {
  transition: all 0.5s ease;
}
@media (max-width: $mq-sm) {
  .animation-horizontal-row {
    animation: 0.8s ease 0s 1 keyframes;
  }

  @keyframes keyframes {
    0% {
      transform: translateX(50%);
    }
  }
}
