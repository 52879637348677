.login {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom right, $grey-dark, $black);

  .logo {
    margin: 3rem 0 2.5rem 0;

    img {
      width: 60%;
      height: auto;
    }
  }

  .container {
    max-width: $mq-xl;
  }

  .panel {
    @include box-shadow(0.9, 25px, 3px);

    button {
      width: 100%;
    }

    .footer {
      padding: 0.85rem 0 0 0;
      font-size: 0.85rem;
    }
  }

  .password-icon {
    position: relative;
  }

  .password-icon i {
    position: absolute;
    right: 15px;
    top: 21px;
    cursor: pointer;
    color: $grey;
  }
}
