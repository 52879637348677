.geoindicators-new {
  padding-bottom: calc(65px + 2rem);

  .time-parting {
    .alert {
      margin-top: -1rem !important;
    }

    .optional {
      color: $grey;
    }

    .filter-choice {
      border-radius: 15px;
      display: inline-block;
      margin: 1rem 0.15rem;
      padding: 0.25rem 0.75rem;
      vertical-align: 40%;
      background: $grey;
      color: $white;

      &.primary {
        font-weight: bold;
        background: $primary;
      }
    }

    .time-select {
      display: block;
      width: auto;
      margin-top: 0.75rem;

      select {
        display: block;
        width: auto;
      }
    }
  }

  .panels {
    display: flex;
    flex-direction: column;
  }

  .breadcrumbs {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: $grey;
  }

  p.margin-top {
    margin-top: 2rem;
  }

  .license {
    .details {
      p {
        margin-bottom: 0;
      }

      h3 {
        margin-top: 0;
      }
    }
  }

  .title-separator h3 {
    margin-top: 2rem;
  }

  .invalid-field {
    border: 1px solid $red !important;
    box-shadow: 0 0 0 1px $red;
  }

  .warning-field {
    border: 1px solid $yellow !important;
    box-shadow: 0 0 0 1px $yellow;
  }

  .price {
    .before-discount {
      display: inline-block;

      span {
        display: block;
        color: $grey;
        font-weight: bold;
        font-size: 0.9rem;
        text-decoration: line-through;
      }
    }
  }

  @media (max-width: $mq-lg) {
    padding-bottom: 0;
  }

  @media (max-width: $mq-sm) {
    .breadcrumbs {
      .text-right {
        text-align: left;
      }
    }

    .panel .actions {
      float: none;
      text-align: inherit;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

@media (min-width: $mq-sm) {
  .report-creation {
    &.steps-bar {
      display: flex;
      margin-bottom: 2rem;
    }
  }
}
