.panel {
  &.no-padding {
    padding: 0;
    overflow: hidden;
  }

  &.summary {
    font-size: 0.9rem;

    h3 {
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
    }
  }

  &.dark {
    background: $black;
    color: $grey;
  }

  &.grid {
    .title {
      margin: 1rem 0;
    }
  }

  .title {
    .badge {
      vertical-align: 10%;
      margin: 0 0 0 0.5rem;
    }
  }
}

[class*='col-'] {
  .panel:not(:first-of-type) {
    margin-top: 1.5rem;
  }
}
