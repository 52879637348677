.sample {
  .ag-grid {
    .ag-row .ag-cell {
      display: grid;
      align-items: center;
    }

    ul {
      padding-left: 15px;

      li {
        line-height: 1.5;
      }
    }

    p {
      margin: 0;
      line-height: 1.5;
    }
  }

  .upload-choice {
    .choice {
      @include border-radius;
      @include transition;
      position: relative;
      text-align: center;
      border: 2px solid $grey-light;
      cursor: pointer;
      padding: 1rem 1rem 0.25rem 1rem;

      &:hover {
        border-color: var(--primary);
      }

      &.active {
        border-color: var(--primary);
      }

      .icon {
        @include transition;
        font-size: 2rem;
      }
    }
  }

  .breadcrumbs {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: $grey;

    a {
      color: $grey;
    }
  }
}
