.menu {
  .brand {
    padding: 1rem;
  }

  .languages {
    @include transition;
    position: absolute;
    bottom: 3rem;
    width: 100%;
    color: $grey;

    a {
      display: inline;
      padding: 0.25rem;
      color: $grey;

      &.selected {
        font-weight: bold;
        color: $white;
      }

      &:hover {
        color: var(--primary);
        text-decoration: none;
      }
    }
  }
}
