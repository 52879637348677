.knowledge {
  .hubspot-iframe {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 240px);
    height: 100%;
    border: 0;
  }

  @media (max-width: $mq-lg) {
    .hubspot-iframe {
      width: 100%;
    }
  }
}
