.welcome {
  .title-bar {
    text-align: center;
  }

  .feature {
    height: 100%;
    text-align: center;

    img {
      width: 100%;
      max-height: 250px;
      margin-top: 1rem;
    }
  }

  .support {
    margin-top: 3rem;
    text-align: center;

    img {
      height: 100px;
      margin-right: 3rem;
      vertical-align: middle;
    }

    h2 {
      display: inline-block;

      .fa,
      .fas,
      .far {
        transform: rotate(45deg);
        font-size: 2rem;
        color: var(--primary);
      }
    }
  }
}
