.popup {
  .popup-content {
    margin: -1rem;
    padding: 0 1rem 1rem 1rem;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;

    .space {
      margin-top: 0.75rem;
    }
  }

  .footer {
    p {
      display: inline-block;
      padding-right: 1rem;
    }
  }
}
