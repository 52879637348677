.report-type-selection {
  padding-bottom: calc(65px);

  .title-separator {
    padding-top: 2rem;

    p {
      margin-top: 0;
      color: $grey;
      font-size: 0.9rem;
    }
  }

  .report-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;

    .choice {
      border: 2px solid transparent;
      cursor: pointer;

      &:hover {
        border-color: $primary;
      }

      &.active {
        border-color: $primary;
      }

      img {
        width: 100%;
        max-width: 100px;
        height: 100px;
      }
    }

    .header {
      display: flex;

      .left {
        display: flex;
        flex-direction: column;
        margin-top: 0;
      }

      .right {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        width: 100%;

        .report-type {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0;
        }

        p {
          margin: 0;
          min-height: 46px;
        }

        .badge-container {
          display: flex;
        }

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    li div {
      margin: 0;
    }
  }

  @media (max-width: $mq-xxl) {
    .report-list {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: $mq-lg) {
    padding-bottom: 0;
  }

  @media (max-width: $mq-sm) {
    .header {
      flex-direction: column;
      text-align: center;

      .left {
        margin: 0 auto 1rem;
      }

      .right {
        flex-direction: column-reverse;
        margin-left: 0 !important;

        .report-type {
          justify-content: center !important;
          gap: 0.5rem;
        }

        .actions {
          flex-direction: column-reverse;
          text-align: center;
          gap: 0.5rem;
          margin: auto !important;
        }
      }
    }
  }
}
