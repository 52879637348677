.campaigns {
  header {
    input {
      margin-right: 1rem;
    }

    .date-picker {
      width: auto ;
      min-width: 250px !important;
      display: inline-block;
    }

    .selection-dropdown {
      display: inline-block;
      width: auto;
      min-width: 250px;
      margin-left: 0.25rem;
      margin-right: 1rem;

      input,
      .text {
        cursor: pointer;
      }
    }

    .btn.primary {
      height: 40px;
    }
  }

  .campaigns-container {
    .clients {
      .table-head {
        th {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .table {
        tr {
          @include transition;
          cursor: pointer;

          &:hover {
            background-color: $grey-lighter;
          }
        }
      }
    }
  }

  .ag-cell.ag-column-first {
    top: 3px;
  }

  .paging {
    font-weight: bold;
    padding: 0.5rem 0 0.75rem 0;
  }

  .no-results {
    padding: 2rem;

    .icon {
      margin-bottom: 1rem;
      font-size: 4rem;
      color: $grey-light;
    }

    .btn {
      margin-top: 1rem;
    }
  }

  .disclaimer {
    margin-top: 2rem;
  }

  .mx-input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .text {
    display: block !important;
    max-width: calc(100% - 10px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: $mq-xl) {
    header {
      .btn.primary {
        text-overflow: ellipsis;
      }
    }
  }

  @media (max-width: $mq-lg) {
    header {
      .date-picker {
        width: 100% !important;
      }

      .selection-dropdown {
        width: 100%;
        margin-left: 0;
        margin-bottom: 0.5rem;
      }

      .right-actions {
        display: block;
      }
    }
  }
}

.mx-datepicker-main.mx-datepicker-popup {
  z-index: 10;
}
