.tree-view {
  height: auto;
  max-height: 500px;
  overflow-x: auto;
}

.org-chart,
.selected-org-chart {
  @include border-radius;
  background: none;
  border: none;
  font-family: $font-stack;
  font-size: 15px !important;
  font-weight: 300;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  padding: 0 !important;
}

.org-chart.google-visualization-orgchart-node-medium:not([title]) {
  border: none !important;
  box-shadow: none !important;
}

.google-visualization-orgchart-connrow-medium,
.google-visualization-orgchart-table tr {
  height: 25px !important;
}

.google-visualization-orgchart-connrow-medium:hover,
.google-visualization-orgchart-noderow-medium:hover {
  padding: 5px;
  background-color: transparent !important;
  cursor: default;
}

.google-visualization-orgchart-table {
  margin: 5px !important;
}

.google-visualization-orgchart-lineleft,
.google-visualization-orgchart-lineright,
.google-visualization-orgchart-linebottom {
  border-color: $grey-light !important;
}

.top-number {
  color: $grey;
}

.label {
  margin-bottom: 5px !important;
}

.node-content {
  @include border-radius;
}
