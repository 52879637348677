.collapse-item {
  position: relative;
  background: $white;
  width: 100%;
  cursor: pointer;
  overflow: hidden;

  .plus,
  .minus {
    &::after {
      @include border-radius;
      content: '';
      position: absolute;
      top: 9px;
      right: 18px;
      width: 3px;
      height: 18px;
      background: $black;
      transition: 500ms;
    }
  }

  .plus {
    &::after {
      transform: rotate(90deg);
    }

    &.expanded {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }

  .minus {
    &.expanded {
      &::after {
        transform: rotate(90deg);
      }
    }
  }

  .question {
    padding-right: 2.5rem;
    font-weight: bold;
  }
}
