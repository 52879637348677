.tourism-report {
  .panel {
    &.grid {
      .title {
        margin-bottom: 0;
      }
    }
  }

  .heatmap-panel {
    margin: 2rem 0;
    padding-bottom: 1rem;

    .heatmap {
      height: 600px;
      margin: -1rem;

      .heatmap-wrapper {
        @include border-radius;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
        overflow: hidden;

        .vue-map {
          height: 600px;
        }
      }
    }
  }
}
