.visits-origin {
  &.grid {
    .title {
      margin-bottom: 0;
    }
  }

  .ag-grid {
    .ag-root {
      min-height: 300px;
    }

    .ag-body-viewport {
      max-height: 500px;
      overflow-y: scroll;
    }

    span.percent {
      color: $grey;
      font-size: 0.8rem;
    }
  }
}
