.credit-cards {
  .method {
    .provider {
      margin-right: 1rem;
      vertical-align: middle;
      font-size: 2.25rem;
      color: $grey;
    }

    small {
      margin-left: 1rem;
    }
  }

  .payment-exception:hover {
    cursor: unset;
  }

  .badge:hover {
    background: $grey;
  }

  .panel {
    margin-bottom: 2rem;

    table {
      margin: -1rem;

      tbody tr {
        height: 50px;
      }

      .ag-row:last-child {
        border-bottom: none;
      }

      .cell-loading {
        opacity: 0.5;
        cursor: progress;
      }
    }
  }
}
