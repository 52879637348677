.custom-heatmap-container {

  margin: -1rem;

  label {
    i {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      border-radius: 50%;
    }
  }

  .heatmap-container {
    .heatmap {
      padding-top: 30px;

      .heatmap-panel {
        height: 600px;

        .heatmap-wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
      }
    }

    .vue-map {
      height: 650px;
    }

    .gm-style {
      font-family: $font-stack;
    }
  }

  .entry-exit-cluster {
    span {
      font-family: $font-stack;
      font-weight: 700;
      font-size: 14px;
      color: $white;
    }
  }
}
