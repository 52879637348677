.file-upload {

  .upload-choice {

    .disabled-cursor {
      cursor: not-allowed !important;
    }

    .choice {
      @include border-radius;
      @include transition;
      position: relative;
      text-align: center;
      border: 2px solid $grey-light;
      cursor: pointer;
      padding: 1rem 1rem 0.25rem 1rem;

      &:hover {
        border-color: var(--primary);
      }

      &.active {
        border-color: var(--primary);
        cursor: default !important;
      }

      &:not(.active) {
        &.disabled {
          pointer-events: none;
          color: $grey;
        }
      }

      .icon {
        @include transition;
        font-size: 2rem;
      }
    }
  }

  .upload-zone {

    .upload-input {
      @include border-radius;
      @include transition;
      position: relative;
      margin-top: 1rem;
      text-align: center;
      border: 2px dashed $grey-light;
      cursor: pointer;
      padding: 1.5rem 1rem 0.5rem 1rem;

      &:hover {
        border-color: var(--primary);
      }

      .files-input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        margin: 0;
      }

      .icon {
        font-size: 2rem;
      }

      a {
        position: relative;
        z-index: 2;
      }
    }
  }

  .files-tab {
    margin-top: 1rem;
  }

  .filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
}
