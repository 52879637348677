.invoices {
  .method {
    .provider {
      margin-right: 1rem;
      vertical-align: middle;
      font-size: 2.25rem;
      color: $grey;
    }

    small {
      margin-left: 1rem;
    }
  }

  .panel {
    margin-bottom: 2rem;

    .loading {
      box-shadow: none;
    }

    .table {
      margin: -1rem;

      .ag-row:last-child {
        border-bottom: none;
      }

      .cell-loading {
        opacity: 0.5;
        cursor: progress;
      }
    }
  }
}
