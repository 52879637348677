.message {
  padding: 3rem;

  .icon {
    margin-bottom: 1rem;
    font-size: 3rem;
    color: var(--primary);
  }

  h3 {
    margin-bottom: 1rem;
  }
}
