
.autocomplete-container {
  padding-right: 8px;
  width: 60%;
}

.autocomplete-input {
  height: 36px;
  font-size: 15px;
  padding: 5px;
  padding-left: 12px;
}
