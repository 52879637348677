/* Colors */
:root {
  --primary:         #ffa000;
  --primary-rgb:     255, 160, 0;
  --primary-darker:  #cc8100;
  --primary-darkest: #a66900;
}

$primary:        #ffa000;
$secondary:      #000;

$white:          #fff;
$grey-lighter:   #f5f5f5;
$grey-light:     #dcddde;
$grey:           #969696;
$grey-dark:      #333;
$black:          #000;

/* Alert colors */
$green:          #008060;
$yellow:         #ffc453;
$red:            #d82c0d;
$blue:           #3498db;
$purple:         #9b59b6;

/* Font */
$font-stack:    'Poppins', sans-serif;

/* Media Queries */
$mq-xxl:              1400px;
$mq-xl: 							1200px;
$mq-lg: 							992px;
$mq-md: 							767px;
$mq-sm: 							576px;

/* Transitions */
$transition:          all 250ms;
