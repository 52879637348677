.tabs {
  border-bottom: 1px solid $grey-light;
  overflow-x: auto;
  white-space: nowrap;

  .tab {
    display: inline-block;
    margin: 0 0.5rem;
    padding: 1rem 1rem calc(1rem - 2px) 1rem;
    color: $grey;
    border-bottom: 3px solid $white;

    &:hover {
      text-decoration: none;
      color: $black;
      border-bottom: 3px solid $grey;
    }

    &.active {
      color: $black;
      border-bottom: 3px solid $primary;
    }
  }
}
