.geoindicators-reports {

  .title-bar {
    a.dropdown:not(:last-child) {
      margin-right: 0.25rem;
    }
  }

  .sample {
    position: relative;
    padding: 4px 0 0 0;
    background: linear-gradient(to right, rgba(255, 191, 102, 1), rgba(255, 115, 72, 1));

    .module {
      @include border-radius;
      position: relative;
      padding: 1rem;
      background: $white;

      &::before {
        content: '';
        position: absolute;
        display: initial;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 25%, rgba(255, 191, 102, 0.25));
      }

      .col-title {
        padding-left: 0;
        color: $black;
      }

      p {
        position: relative;
        padding-left: 2rem;
        font-size: 0.8rem;
        color: $grey;

        b {
          display: block;
          font-size: 1.25rem;
          color: $black;
        }

        i.tooltip-icon {
          position: relative;
          top: -2px;
          font-size: 1rem;
        }
      }

      i.fas:not(.tooltip-icon) {
        position: absolute;
        left: -7px;
        top: 7px;
        font-size: 1.5rem;

        &::before {
          background: linear-gradient(90deg, #ffa000, #ff4b00);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  .title-separator {
    padding-top: 2rem;

    h1 {
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
      color: $grey;
    }
  }

  h2 {
    i.tooltip-icon {
      position: relative;
      top: -2px;
      font-size: 1rem;
    }
  }

  .panel {
    &.no-padding {
      padding-bottom: 1rem;
    }

    .actions {
      display: flex;
      gap: 1rem;
    }

    .legend-list {
      cursor: default;

      .legend-title {
        margin-left: 1rem;
      }

      .legend-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.9rem;

        &:hover {
          background: $white;
        }

        .legend-circle {
          @include border-radius;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }

    &.loading-chart {
      position: relative;
      padding-top: 3rem;
      backdrop-filter: blur(10px);

      img {
        width: 40px;
        height: auto;
        display: inline-block;
      }

      &.no-padding {
        padding-bottom: 0;
      }
    }

    .loading-title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 6rem;
      text-shadow: 0 0 10px $white;
      backdrop-filter: blur(10px);
      z-index: 1;
    }

    .top-title {
      padding: 1rem;
    }

    .metric-title {
      h2 {
        margin: 0;

        span {
          font-size: 1rem;
          font-weight: normal;
        }
      }

      p {
        margin: 0;
      }

      &.highlight {
        position: relative;

        .icon-wrapper {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          right: 0;
          top: 0;
          width: 55px;
          height: 55px;
          font-size: 1.5rem;
          border-radius: 50%;
          background: lighten($primary, 40);

          .fas {
            position: absolute;
            font-size: 1.5rem;
            background-clip: text;
            -webkit-background-clip: text;
            text-fill-color: transparent;
            -webkit-text-fill-color: transparent;
            background-color: $primary;
            background-image: linear-gradient(to right, $primary 40%, adjust-hue($primary, -20), adjust-hue($primary, -50));
          }
        }
      }
    }

    &.big {
      margin-bottom: 2rem;

      .name {
        padding-bottom: 1rem;
        text-align: center;
      }

      .metric-title {
        h2 {
          text-align: left;
          margin-left: 1rem;
          line-height: 1.2;
        }

        &:not(:last-child) {
          border-right: 1px solid $grey-light;
        }
      }
    }
  }

  a.dropdown {
    color: $black;

    &.primary {
      color: $white;
    }

    ul {
      color: $black;

      &.layers li label {
        margin-top: 0;
      }
    }
  }

  .btn.dropdown {
    .fences-list {
      padding: 0;
      overflow-y: scroll;
      width: 300px;

      .fence-selector {
        position: sticky;
        background-color: $white;
        z-index: 1;

        &.top {
          top: 0;
          padding-top: 0.5rem;
        }

        &.bottom {
          bottom: 0;
          padding-bottom: 0.5rem;
        }
      }

      .fence {
        padding: 0;
      }
    }

    .actions-list {
      width: 300px;
    }

    .select-unselect-all {
      position: relative;
      cursor: pointer;
      margin: 0;

      .fas,
      .far {
        position: absolute;
        left: 0.75rem;
        font-size: 1.7rem;
        color: var(--primary);
      }
    }

    .confirm-selection {
      padding: 0.5rem 1rem;
      margin-left: 1rem;
    }

    .switch {
      margin: 1rem 0.5rem;
    }
  }

  label.select-unselect-all {
    display: flex;
    align-items: center;

    i {
      border: none;
    }
  }

  label.checkbox,
  label.select-unselect-all {
    margin: 0.5rem 0 0 0;
    padding: 0.75rem 0 0.75rem 3rem;

    span {
      top: 0.75rem;
      left: 0.75rem;
    }

    i.color {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 0.5rem 0 0;
      vertical-align: middle;
      border-radius: 50%;
    }
  }

  .breadcrumbs {
    color: $grey;
    margin-bottom: 1rem;
    font-size: 0.9rem;

    .badge {
      display: inline-block;
    }
  }

  .badge.beta {
    vertical-align: 7%;
  }

  .no-report {
    img {
      padding: 3rem;
      width: 100%;
    }

    .text {
      padding-left: 3rem;
    }

    h1 {
      b {
        background: linear-gradient(90deg, #ffa000, #ff4b00, #ff0035, #ffa000, #ff4b00, #ff0035);
        background-size: 400% 100%;
        font-size: 2.75rem;
        color: var(--primary);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: gradient-text 12s linear infinite;
      }

      sup {
        border-radius: 3px;
        position: relative;
        padding: 0.25rem;
        top: -0.5rem;
        background: #444;
        font-size: 1rem;
        color: $grey-light;
      }
    }

    .keypoint {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          position: relative;
          padding: 0.75rem 0 0 15px;

          &::before {
            content: "\2022";
            position: absolute;
            left: 0;
            color: var(--primary);
            font-weight: 700;
          }
        }
      }
    }

    .cta {
      margin: 2rem 0 1rem 0;
      font-weight: bold;
      color: $white;
    }

    .cta-link {
      margin-left: 2rem;
      color: $grey;
    }
  }

  .search-box {
    position: relative;
    display: inline-block;
    min-width: 250px;
    padding: 0;
    margin-right: 1rem;

    input {
      padding-left: 2rem;
    }

    .search-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: $grey;
    }

    .clear-filter-btn {
      position: absolute;
      right: 0;
      background: none;
      border-color: transparent;
      box-shadow: none;
      color: $grey;

      i {
        margin: 0;
      }
    }
  }

  .clear-filter-btn:hover {
    color: $grey-dark;
  }

  .partners {
    margin: 4rem auto 2rem;
    text-align: center;
    color: $grey;

    p {
      font-weight: bold;
    }

    img {
      max-width: 145px;
      display: inline-block;
      opacity: 0.4;
    }
  }
  
  .preview {
    filter: blur(25px);
    pointer-events: none;
  }

  .sticky-form-parent{
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 40%;
    top: 60%;
    filter: none !important;

    &.visits {
      height: 80%;
      top: 20%;
     }

     &.trails {
      height: 85%;
      top: 15%;
     }

     &.market {
      height: 50%;
      top: 50%;
     }

     &.tourism {
      height: 67%;
      top: 33%;
     }

     &.before-after {
      height: 43%;
      top: 57%;
     }
  }

  .sticky-form {
    width: 50vw;
    left: 25vw;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    padding: 1rem;
    background: $white;
    border: 1px solid $grey-light;
    border-top: 7px solid $primary;
    border-radius: 5px;
    filter: none;

    &.sticky {
      position: sticky !important;
    }

    &.fixed {
      position: fixed !important;
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .space {
      margin-top: 0.75rem;
    }

    h3 {
      text-align: center;
    }

    hr {
      margin-left: -1rem;
      margin-right: -1rem;
    }

    .field-error {
      color: $red;
      font-size: 0.9rem;
    }
  }

  &.public {
    position: relative;
    top: 0;
    left: 0;

    .title-bar {
      width: 100% !important;
      left: 0 !important;
    }

    .container.public-container {
      left: -120px;
    }

    .logo {
      max-width: 145px;
      display: block;
      margin: 4rem auto 2rem auto;
    }

    @media (max-width: $mq-lg) {
      top: -75px;

      .container.public-container {
        left: 0;
      }
    }
  }

  .cross-visits {
    .apexcharts-xaxis-texts-g .apexcharts-text:last-child {
      font-size: 1rem;
      font-weight: bold;
    }
    
    .apexcharts-menu-item.exportSVG, .apexcharts-menu-item.exportPNG {
      display: none;
    }
    
    .apex_custom_tooltip {
      max-width: 500px;
      white-space: normal;
      word-break: break-word;
    }
  }

  .custom-report {
    position: fixed;
    width: calc(100% - 240px - 6rem);
    height: calc(100% - 65px - 8rem);
    left: calc(240px + 3rem);
    top: calc(65px + 5rem);
    padding: 0;

    @media (max-width: $mq-lg) {
      position: relative;
      width: 100%;
      height: 700px;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: $mq-md) {
    .report-name {
      white-space: normal !important;
      max-width: none !important;
    }
  }
}
