.v-popper__popper--arrow-overflow .v-popper__arrow-container,
.v-popper__popper--no-positioning .v-popper__arrow-container {
  display: none;
}

.v-popper__popper {
  display: block !important;
  max-width: 300px;
  z-index: 10000;

  &.error {
    .v-popper__inner {
      background: $red;
    }

    .v-popper__arrow-outer {
      border-color: $red;
    }
  }

  .v-popper__arrow-container {
    position: absolute;
    width: 10px;
    height: 10px;
  }

  .v-popper__arrow-outer {
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    margin: 5px;
    border-style: solid;
    position: absolute;
    border-color: $black;
    z-index: 1;
  }

  &[data-popper-placement^="top"] {
    margin-bottom: 10px;

    .v-popper__arrow-outer {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: 5px;
      left: calc(50% - 10px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[data-popper-placement^="bottom"] {
    .v-popper__arrow-outer {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      left: calc(50% - 10px);
      top: -10px;
    }

    .v-popper__arrow-container {
      top: 0;
    }
  }

  &[data-popper-placement^="right"] {
    margin-left: 10px;

    .v-popper__arrow-outer  {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 10px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[data-popper-placement^="left"] {
    margin-right: 10px;

    .v-popper__arrow-outer {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 10px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.v-popper {
    $color: #f9f9f9;

    .v-popper__inner {
      background: $color;
      color: $black;
      padding: 24px;
      border-radius: 5px;
    }

    .v-popper__arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.v-popper__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.v-popper__inner {
  @include border-radius;
  padding: 1rem;
  background: $black;
  color: $white;
  font-size: 0.9rem;
  position: relative;
  box-sizing: border-box;
  overflow-y: visible;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.v-popper__inner > div {
  position: relative;
  z-index: 1;
  max-width: inherit;
  max-height: inherit;
}

.tooltip-icon {
  &.fa-question-circle {
    margin-left: 0.25rem;
  }
}
