.internal {
  .title {
    display: flex;
    align-items: center;
  }

  hr {
    margin: 2rem 0;
  }

  label {
    margin: 0.5rem 0 0 0;
  }

  small {
    color: $grey;
  }

  table {
    th,
    td {
      padding: 0.5rem 1rem;
    }

    .badge {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  }

  li {
    margin: 1rem 0;
  }
}
