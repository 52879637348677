.ui.selection {
  @extend input;

  .search {
    margin: 0;
  }

  &.active.dropdown,
  &.dropdown:hover,
  &.active.dropdown:hover {
    border-color: $grey !important;

    .menu,
    .div.menu.visible,
    .menu:hover {
      border-color: $grey !important;
    }
  }

  &.disabled {
    background: $grey-lighter !important;
    opacity: 1 !important;
    cursor: not-allowed !important;
  }

  .menu {
    @include box-shadow;
    height: 250px;
    top: calc(100% - 3px) !important;
  }
}

.btn.dropdown {
  .list {
    li.disabled {
      &:active {
        pointer-events: none;
      }
    }
  }

  .no-checkbox {
    li.separator,
    input[type="checkbox"],
    label.checkbox span {
      display: none;
    }

    label.checkbox {
      padding: 0.75rem 0 0.75rem 0.75rem;
    }
  }
}

.vs__search {
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  caret-color: transparent;
  --vs-search-input-placeholder-color: #c7c7c7;
  --vs-controls-size: 0.5;

  &:focus-visible {
    padding: 0;
    margin: 0;
    caret-color: $black;
    --vs-search-input-placeholder-color: #858585;
  }
}

.vs__dropdown-option {
  padding: 0.65rem 1rem;
  font-size: 14px;
  --vs-dropdown-color: $black;
  --vs-dropdown-option--active-bg: #f7f7f7;
  --vs-dropdown-option--active-color: $black;
}

.vs__selected-options {
  padding: 0;
}

.vs__selected {
  padding-left: 0;
  margin: 0;
}

.vs__actions {
  cursor: pointer;
  padding: 0;
}

.vs__dropdown-menu {
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.vs__dropdown-toggle {
  margin: 0.5rem 0;
  padding: 0.45rem 1rem;
  height: 40px;

  &:hover {
    border: 1px solid $grey;
  }
}


