.radar {
  padding-bottom: 0;

  table {
    width: 100%;
    height: calc(100% + 1rem);
    margin: -1rem -1rem -1rem 1rem;
    font-size: 12px;
    font-weight: normal;
    color: $grey;
    border-radius: 0;
    border-left: 1px solid $grey-light;

    .value {
      font-weight: bold;
      text-align: right;
    }

    td {
      padding: 0.75rem;
    }

    tr:first-of-type {
      td {
        border-top: none;
      }
    }

    tr:hover {
      background-color: $white;
      cursor: default;
    }
  }

  @media(max-width: $mq-xl) {
    padding-bottom: inherit;

    table {
      height: 100%;
      margin: 0;
      border-left: none;
    }
  }
}
