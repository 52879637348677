@media (max-width: $mq-md) {
  .horizontal-row {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    [class*='col-'] {
      display: inline-block;
      margin-left: 1rem;
      padding: 0;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .actions-bar {
    .btn {
      width: 100%;
      text-align: center;
    }
  }

  .container {
    padding: 0 !important;
  }

  .badge {
    display: inline-block;
  }

  .panel.video {
    iframe {
      min-height: 300px !important;
    }
  }

  .fas {
    margin: 0 !important;
  }
}
