.videos {
  .video-list {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      padding: 0;
      display: block;
      width: 100%;
      color: $black;
      text-decoration: none;

      i {
        @include transition;
        color: $grey;
      }

      &:hover {

        i {
          color: var(--primary);
        }
      }

      &.selected {
        font-weight: bold;
        color: var(--primary);
      }
    }
  }

  .panel.video {
    padding: 0;
    overflow: hidden;

    iframe {
      display: block;
      width: 100%;
      height: 100%;
      min-height: 500px;
    }
  }
}
