table {
  tbody {
    td,
    th {
      white-space: nowrap;
    }

    tr {
      @include transition;

      &:hover {
        background-color: $grey-lighter;
        cursor: pointer;
      }
    }

    .badge {
      @include transition;
      display: block;
      width: fit-content;
      margin: 0;

      a {
        color: $white;
      }

      &:hover {
        background: darken($red, 15);

        a {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  &.no-hover {
    tbody {
      tr {
        &:hover {
          background-color: inherit;
          cursor: unset;
        }
      }
    }
  }
}
