.campaigns.congratulation {
  .far {
    margin: 2rem 0;
    animation: grow 450ms;
    color: $green;
  }

  .btn {
    display: block;
    width: fit-content;
    margin: 2rem auto;
  }
}

@keyframes grow {
  0% {
    transform: scale(0);
  }

  85% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
