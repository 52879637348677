.summary-container {
  display: flex;
  flex-direction: column;

  .content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .category {
      margin-bottom: 0;
      color: $grey;
      font-size: 0.9rem;
    }

    .text {
      margin: 0 0 1rem;
      font-weight: bold;
      width: fit-content;
    }

    .method {
      .provider {
        margin-right: 1rem;
        vertical-align: middle;
        font-size: 2.25rem;
        color: $grey;
      }

      small {
        margin-left: 1rem;
        font-weight: normal;
      }
    }
  }

  @media (max-width: $mq-lg) {
    .content {
      grid-template-columns: 1fr;
    }
  }
}
