.economic-report {
  .panel {
    &.no-padding {
      padding-bottom: 0;
    }

    &.grid {
      margin-top: 2rem;
    }
  }
}
