iframe {
  max-width: 100%;
}

.campaigns-edit {
  .status {
    position: relative;
    margin: 0 0 2rem 0;

    .steps-bar {
      display: flex;
    }
  }

  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background: rgba($black, 0.5);
    backdrop-filter: blur(5px);

    .container {
      padding: 0 12rem;

      .subtitle {
        display: inline-block;
        padding: 0 1rem;
      }

      .content {
        position: relative;

        .close {
          color: $black;
          position: absolute;
          right: 2rem;
          top: 1rem;
          font-size: 2rem;
          cursor: pointer;
        }
      }
    }
  }

  .publish {
    margin-right: 0.5rem;
  }

  .breadcrumbs {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: $grey;

    a {
      color: $grey;
    }
  }

  .pending {
    .panel {
      margin-top: 3rem;
      padding: 2rem;
    }

    .icon {
      font-size: 3rem;
      color: var(--primary);
    }
  }

  .goals {
    .fa,
    .fas,
    .far,
    .fal,
    .fab {
      width: 30px;
      font-size: 1.25rem;
      text-align: center;
    }
  }

  .flight {
    &:last-child {
      .divider {
        display: none;
      }
    }

    .actions {
      padding-top: 1rem;
      text-align: right;
    }
  }

  .panel {
    .divider.info {
      margin: 3rem -1rem;
    }
  }

  .recommandations {
    list-style: none;
    padding: 0;

    .panel {
      margin: 0.75rem 0;
      border-left: 4px solid $grey-light;
    }

    li {
      margin: 0.75rem 0;
      padding: 0 0 0 2.5rem;
    }

    .fa,
    .fas,
    .far,
    .fal,
    .fab {
      position: absolute;
      left: 1.5rem;
      width: 25px;
      vertical-align: middle;
      font-size: 1.25rem;
      color: $grey-light;
    }
  }

  @media (max-width: $mq-lg) {
    .status {
      .steps-bar {
        display: block;
      }
    }
  }
}
