.fences-builder {
  .map-wrapper {
    height: 500px;
    padding: 0;
    margin-top: -1rem;
  }

  .google-map {
    height: 100%;
    width: 100%;
  }

  &.panel {
    padding-bottom: 0;
  }

  .actions {
    a + a {
      margin-left: 1.5rem;
    }
  }

  .tabs {
    padding: 0;
    margin-top: -1rem;
    margin-bottom: 1rem;
    width: 100%;

    .badge {
      vertical-align: 10%;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .row {
      pointer-events: none;
    }

    .map-disabled {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.5);
      transition: opacity 0.5s, visibility 0.5s;
      z-index: 1;
    }
  }

  .fence-alert {
    margin-top: -1rem !important;
  }

  .responsive-map {
    height: calc(75vh - 150px);
    max-height: 650px;
  }

  .popup.import-popup {
    padding: 0;

    .container {
      margin-top: 2rem;
      padding: 0;
      height: 100%;

      .panel {
        max-height: 100%;

        &.list {
          height: 90%;
          max-height: 800px;

          .footer {
            margin-top: 0;
            padding: 1rem;
          }
        }

        .import-success {
          height: 100%;

          td span.badge {
            display: unset;

            &.success:hover {
              background: $green;
            }

            &.danger:hover {
              background: $red;
            }
          }
        }

        .row {
          height: 85%;
          max-height: 100%;

          .fence-to-import {
            height: 100%;
            padding-right: 0;
            padding-left: 0;
            overflow-y: scroll;

            .fence-table {
              margin-left: auto;
              margin-right: auto;
              width: 100%;
              margin: 0 auto;
              overflow-y: scroll;

              thead {
                th {
                  position: sticky;
                  top: 0;
                  background: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  .fences-list {
    height: 500px;
    margin: -1rem;
    padding: 0;

    .fence {
      @include transition;
      padding: 1rem;
      border-top: 1px solid $grey-light;
      text-align: right;
      cursor: pointer;

      &.subfence {
        position: relative;
        color: $grey;
        border-top: 0;
        margin: 0 0 1rem 3rem;
        padding: 1rem 1rem 2rem 1rem;
        border-left: 2px solid $grey-light;
        text-align: left;

        .fa-subfence {
          position: absolute;
          left: -1.5rem;
        }

        label {
          overflow-wrap: break-word;
        }
      }

      .fence-actions {
        text-align: right;
        position: relative;

        small.red {
          display: block;

          i {
            padding-right: 0.25rem;
          }
        }

        a {
          margin: 0 0 0 0.75rem;
        }
      }

      .badge {
        float: left;
      }

      &.error {
        background-color: lighten($red, 53);
      }

      .divider {
        margin: 1rem -1rem 0 -1rem;
      }

      &:hover {
        background: $grey-lighter;
      }
    }

    .empty {
      padding: 5rem 1rem;
      text-align: center;

      .map {
        display: block;
        font-size: 6rem;
        color: $grey-light;
      }

      .cursor {
        position: relative;
        display: block;
        font-size: 2rem;
        top: -1rem;
        right: -3rem;
      }

      p {
        margin-top: 2rem;
      }
    }
  }

  @media (max-width: $mq-md) {
    .fences-list {
      height: 375px;
      margin: 1rem -1rem -1rem -1rem;
    }
  }

  .max-area-warning {
    margin: 1rem 0 0 1rem;
  }

  .vue-map-container {
    position: relative;
    margin: -1rem;

    .vue-map {
      height: 500px;
      border-right: 1px solid $grey-light;

      .gmnoprint span,
      .gmnoprint a,
      .gm-style-cc a,
      .gm-style-cc:not(:nth-child(2)) {
        display: none !important;
      }

      a[href^="http://maps.google.com/maps"],
      a[href^="https://maps.google.com/maps"] {
        display: none !important;
      }
    }

    .vue-map-hidden {
      display: block;
      position: absolute;
      top: 8px;
      right: 9px;
      width: calc(100% - 215px);

      input {
        @include box-shadow;
        border-radius: 2px;
        height: 35px;
        margin: 0;
        border: none;
      }
    }
  }

  .overlay {
    position: absolute;
    top: -1rem;
    bottom: -1rem;
    left: 0;
    right: 0;
    z-index: 2;
    line-height: 500px;
    text-align: center;
    background-color: rgba($black, 0.5);
    color: $white;
    pointer-events: none;
  }

  &.fullscreen {
    .google-map {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0;
      z-index: 100;
      overflow: hidden;

      .close {
        @include box-shadow;
        position: fixed;
        top: 8px;
        right: 8px;
        width: 200px;
        margin: 0;
        padding: 6px 0;
        border: none;
        border-radius: 2px;
        text-align: center;
      }

      .vue-map {
        height: 100%;
        margin: 0;
        border: none;

        .gm-style-cc:nth-child(2) {
          left: calc(50% - 50px) !important;
          right: auto !important;
        }
      }

      .vue-map-hidden {
        position: fixed;
        top: 8px;
        left: calc(50% - 250px);
        width: 500px;
      }
    }

    .fences-list {
      @include transition;
      position: fixed;
      bottom: 8px;
      right: 8px;
      width: 300px;
      height: calc(100% - 200px);
      margin: 0;
      z-index: 150;
    }
  }

  .scroller {
    height: 100%;
    overflow-y: auto;
  }

  @media (max-width: $mq-md) {

    .close {
      width: 60px !important;
    }

    &.fullscreen {
      .fences-list {
        right: 15px;
        width: 100%;
        height: 50%;
        margin: -15px;
      }
    }

    .fences-list.closed {
      @include transition;
      height: 10%;
    }
  }

  @media (max-width: $mq-lg) {
    &.fullscreen {
      .vue-map-hidden {
        top: 50px !important;
        left: 2% !important;
        right: 2% !important;
        width: auto !important;
      }
    }
  }
}
