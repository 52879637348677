.before-after-report {
  .metrics {
    display: flex;

    h2 {
      display: inline-block;
      margin: 1rem 0 !important;

      .fa-question-circle {
        position: relative;
        top: -5px;
        font-size: 0.9rem;
      }
    }

    p {
      margin-right: 4rem !important;
    }

    i.fas.metric {
      position: relative;
      color: $black;
      font-size: 2.5rem;
      background: linear-gradient(to right, rgba(255, 191, 102, 1), rgba(255, 115, 72, 1));
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
