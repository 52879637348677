.apexcharts-tooltip.apexcharts-theme-light {
  border: 0 !important;
}

.apexcharts-xaxistooltip {
  @include border-radius;
  border: 1px solid var(--primary) !important;
  background: $white !important;

  &::before {
    border-bottom-color: var(--primary) !important;
  }

  &::after {
    border-bottom-color: $white !important;
  }
}

.apexcharts-yaxis-annotations {

  rect,
  text {
    opacity: 0;
  }

  line:hover {
    &+rect,
    &+rect+text {
      opacity: 1;
    }
  }
}

.chart-title {
  position: relative;
  padding-left: 2rem;
  color: $grey;

  &.no-indicator {
    padding-left: 2rem;
  }

  h2 {
    font-size: 1rem;
  }

  p {
    font-size: 0.8rem;
  }

  span.indicator {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $grey-light;
  }

  @media (max-width: $mq-md) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.chart-legend {
  color: $grey;

  ul {
    padding: 0;
    list-style: none;
    line-height: 2;
    font-size: 0.9rem;
  }
}

.radar-direction {
  max-width: 450px;
  margin: auto;

  polygon,
  line {
    opacity: 0;
  }

  .apexcharts-canvas {
    padding-top: 3px;
    background: url('/assets/compass.svg') no-repeat center center;
    background-size: auto 300px;
  }
}

.hide-section {
  .apexcharts-inner {
    opacity: 0;
  }

  &.radar-direction {
    .apexcharts-canvas {
      background: none;
    }
  }
}

.apex_custom_tooltip {
  @include border-radius;
  border: 0;
  padding: 0.25rem 1rem;
  background: $grey;
  color: $white;
  line-height: 1.25;
  font-family: $font-stack;

  span {
    display: block;
    font-size: 0.75rem;
  }

  h3 {
    padding: 0.25rem 0;
    margin: 0;
    font-size: 0.85rem;
    font-weight: bold;
  }

  i.color {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 0.5rem 0 0;
    vertical-align: middle;
    border-radius: 50%;
  }
}