.proposition {
  .menu {
    .brand {
      img {
        height: 35px;
        width: auto;
      }
    }

    ul {
      li {
        span {
          display: block;
          padding: 1rem 0 1rem 1rem;
          border-left: 5px solid transparent;
          color: $grey;

          .far,
          .fas,
          .fal
          .fa {
            font-size: 1.25em;
            margin: 0 0.5rem 0 0;
            vertical-align: middle;
          }
        }

        ul {
          margin-bottom: 1rem;

          li {
            a {
              padding-left: 3rem;
            }
          }
        }
      }
    }

    .powered-by {
      position: absolute;
      width: 100%;
      bottom: 1rem;
      text-align: center;

      img {
        width: 75px;
      }
    }
  }

  .pitch {

    >.panel {
      margin: 4rem 0;

      &:first-of-type {
        margin-top: 2rem;
      }

      .panel {
        margin: 2rem 0;
      }
    }

    .icons {
      img {
        width: 100%;
        max-width: 150px;
      }

      h3 {
        color: $grey;
      }
    }

    .slider {
      @include border-radius;
      background: $grey-light;
      z-index: 1;

      .description {
        padding: 2rem 4rem;
      }

      .image {
        text-align: center;
      }

      h3 {
        span {
          display: inline-block;
          padding-right: 0.5rem;
          color: $white;
          font-size: 5rem;
          font-weight: 700;
        }
      }

      img {
        height: 560px;
      }
    }

    blockquote.big {
      position: relative;
      width: 100%;
      margin: 3rem 0 2rem 0;
      padding: 0;
      text-align: center;
      font-size: 1.5rem;

      a {
        margin-left: 0.5rem;
        font-size: 1rem;
        color: $grey-light;
      }
    }

    .citations {
      blockquote {
        @include border-radius;
        position: relative;
        width: 100%;
        padding: 2rem;
        margin: 1rem 0;
        border: 1px solid $grey-light;

        img {
          display: block;
          width: 150px;
          margin: 2rem 0 0 0;
        }

        a {
          position: absolute;
          right: 2rem;
          bottom: 2rem;
          color: $grey-light;
        }
      }
    }

    .slider-plan {
      .params {
        margin-bottom: 1rem;
      }

      .vue-slider-rail {
        margin-top: 18px;
      }
    }

    .summary {
      table {
        .total {
          background: $grey-light;
          font-weight: bold;
          font-size: 1rem;
        }
      }
    }
  }
}
