.tools {
  text-align: justify;

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.75rem 0 0.5rem 0;
  }

  .downloads {
    text-align: center;

    .fa,
    .far,
    .fas {
      display: block;
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
  }

  .question {
    margin: 1.5rem 0 0 0;
    font-weight: bold;
  }

  a {
    color: $grey-dark;

    &:hover {
      color: var(--primary);
    }
  }
}
