.ag-grid {
  font-family: $font-stack;
  font-weight: lighter;
  font-size: 0.9rem;

  .ag-root {
    min-height: 300px;
    border: none;
  }

  .ag-root-wrapper {
    border: none;
  }

  .ag-header {
    z-index: 5;
    background: none;
    border: none;
    border-bottom: 3px solid $grey-light;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: $font-stack;
    color: $black;

    .ag-header-row {
      color: $black;

      .ag-header-cell {
        &::before {
          height: 50px;
          top: 0;
          margin: 0;
        }
      }
    }
  }

  .ag-header-icon,
  .ag-sort-indicator-icon,
  .ag-icon-asc,
  .ag-icon-desc,
  .ag-icon-filter  {
    color: $grey;
  }

  .ag-sort-indicator-container {
    align-items: center;
  }

  .ag-row {
    z-index: 0;

    .ag-icon-checkbox-checked {
      color: var(--primary);
    }
  }

  .ag-row-odd {
    background: none;
  }

  .ag-cell {
    line-height: 35px;
  }

  .ag-cell-focus:not(.ag-cell-range-selected) {
    border: none !important;
  }

  a {
    color: $black;

    &:hover {
      color: inherit;
    }
  }

  .ag-menu {
    .ag-filter {
      padding: 10px;

      input[type="text"] {
        border: 1px solid $grey-light;
        padding: 0.45rem auto;
        font-size: 1rem;
        font-family: $font-stack;

        &:focus {
          border: 2px solid var(--primary);
          box-shadow: none;
        }
      }
    }

    .ag-filter-select,
    .ag-filter-filter {
      margin: 0 0 10px 0;

      &:active {
        border-color: var(--primary);
      }
    }

    .ag-input-wrapper input[type="text"] {
      border-color: $grey-light;

      &::placeholder {
        color: $grey-dark;
      }

      &:focus {
        border-color: var(--primary);
      }
    }

    button {
      margin: 0;
    }
  }

  .ag-floating-bottom {
    border-top: 2px solid $grey-light;
    font-weight: bold;
  }
}

.ag-theme-balham {
  --ag-checkbox-checked-color: var(--primary);
  --ag-selected-row-background-color: $grey-light;
  --ag-row-hover-color: $grey-lighter;

  .ag-row-hover {
    background-color: $grey-lighter;
  }

  .ag-row-selected {
    background-color: $grey-light;
  }
}

.grid-selection-actions {
  animation: expand 350ms;
  background: $black;
  padding: 1rem;
  color: $white;

  a {
    color: $white;
    font-weight: bold;
    margin-left: 1rem;
  }
}

.paging {
  padding: 0.5rem 0 0 0;

  a {
    display: inline-block;
    margin: 0.5rem;
    padding: 0 0.25rem;
    font-size: 1.15rem;
    font-weight: bold;
    color: $black;

    .current-page {
      text-decoration: underline;
    }

    &:hover {
      color: var(--primary);
    }
  }

  @media (max-width: $mq-sm) {
    text-align: center;

    p {
      text-align: center;
    }
  }
}

.ag-filter-title {
  font-family: $font-stack;
  font-weight: bold;
  font-size: 0.9rem;
}
